import { PersonalAreaItemTypeEnum } from "../types/commonTypes";
import { UserInputType } from "../types/regionalHazardType";

export const getUserInputHazardBody = (userInput: UserInputType) => {
    let _body = {
        searchedLocation: userInput && userInput.searchedLocation,
        cap: userInput && userInput.cap,
        drawArea: userInput && userInput.drawArea,
        sectors: userInput && userInput.sectors,
        privacyChecked: userInput && userInput.privacyChecked
    }
    return _body;
}

const getObjProp = (obj: any, pathArr: string[]): any => {
    if (!pathArr || !pathArr.length || !obj) {
        return obj
    }

    const nestedObj = obj[pathArr.shift() || '']
    return getObjProp(nestedObj, pathArr)
}

export function getLocalStorageObj(path: string) {
    let LOappStateJson = getJsonLocalStorageAppState();
    if (LOappStateJson) {
        if (!path.length) {
            return LOappStateJson;
        }
        const pathArr = path.split('.')
        return getObjProp(LOappStateJson, pathArr)
    }
}

export const clearLocalStorageAuthData = () => {
    let LOappStateJson = getJsonLocalStorageAppState();
    if (LOappStateJson) {
        delete LOappStateJson.auth
        localStorage.setItem('adaApplicationState', JSON.stringify(LOappStateJson));
    }
}

export function getLocalStorageToken() {
    return getLocalStorageObj('auth.personalData.token')
}

export function getLocalStorageAccessToken() {
    return getLocalStorageObj('auth.personalData.accessToken')
}

export function saveAuthInfoInLocalStorage(_personalData: any) {
    let LOappStateJson = getJsonLocalStorageAppState();
    if (!LOappStateJson || !LOappStateJson.auth) {
        LOappStateJson = { ...LOappStateJson, auth: {} }
    }
    LOappStateJson.auth.personalData = _personalData
    localStorage.setItem('adaApplicationState', JSON.stringify(LOappStateJson));
}

export const getJsonLocalStorageAppState = () => {
    let appState: any = localStorage.getItem('adaApplicationState');
    if (appState) {

        let LOappStateJson: any = JSON.parse(appState) // re-hydrate the store
        return LOappStateJson
    }
}

export function getLocalStoragePersPlanItemToAdd(itemType: PersonalAreaItemTypeEnum) {
    return getLocalStorageObj(`pers.${itemType}`)
}

export function addPersPlanItemnLocalStorage(itemType: PersonalAreaItemTypeEnum, itemData: any) {
    let LOappStateJson = getJsonLocalStorageAppState();
    if (!LOappStateJson || !LOappStateJson.pers) {
        LOappStateJson = { ...LOappStateJson, pers: {} }
        LOappStateJson.pers[PersonalAreaItemTypeEnum.AREA] = []
        LOappStateJson.pers[PersonalAreaItemTypeEnum.ACTION] = []
    }
    if (itemType === PersonalAreaItemTypeEnum.AREA) {
        LOappStateJson.pers[PersonalAreaItemTypeEnum.AREA].push(itemData)
    } else if (itemType === PersonalAreaItemTypeEnum.ACTION) {
        LOappStateJson.pers[PersonalAreaItemTypeEnum.ACTION].push(itemData)
    }
    localStorage.setItem('adaApplicationState', JSON.stringify(LOappStateJson));
}

export function cleanPersPlanItemnLocalStorage(itemType: PersonalAreaItemTypeEnum) {
    let LOappStateJson = getJsonLocalStorageAppState();
    if (!LOappStateJson || !LOappStateJson.pers || itemType === PersonalAreaItemTypeEnum.ALL) {
        LOappStateJson = { ...LOappStateJson, pers: {} }
        LOappStateJson.pers[PersonalAreaItemTypeEnum.AREA] = []
        LOappStateJson.pers[PersonalAreaItemTypeEnum.ACTION] = []
    } else if (itemType === PersonalAreaItemTypeEnum.AREA) {
        LOappStateJson.pers[PersonalAreaItemTypeEnum.AREA] = []
    } else if (itemType === PersonalAreaItemTypeEnum.ACTION) {
        LOappStateJson.pers[PersonalAreaItemTypeEnum.ACTION] = []
    }
    localStorage.setItem('adaApplicationState', JSON.stringify(LOappStateJson));
}

