import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppDispatch } from "../../store/hooks";
import "./StatusSelectionCmp.scss";
import { statusName } from "../../utils/utils";

type StatusSelectionProps = {
    statusList: string[];
    status: string;
    updateStatus: Function
};

const StatusSelection = (props: StatusSelectionProps) => {
    const { statusList, status, updateStatus } = props;

    const toggleSectorHandler = (selectedStatus: string) => {
        updateStatus(selectedStatus)
    };

    return (
        <>
            <div className="status-selection-cmp">
                <p className="status-info">
                    Le tue organizzazioni potranno vedere i rischi delle tue zone salvate.
                </p>
                {statusList.map((el: string) => {
                    const isItemSelectedClass = el === status
                        ? "is-selected"
                        : "";
                    return (
                        <div
                            className={`sector-item ${isItemSelectedClass}`}
                            key={el}
                            onClick={() => toggleSectorHandler(el)}
                        >
                            <button className={`ada-ck ${isItemSelectedClass}`}>
                                <FontAwesomeIcon icon={faCheck} />
                            </button>
                            <div className="item-name">{statusName[el]}</div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default StatusSelection;
